.imprint-builder-menu-item {
  width: 100%;
  height: 55px;
}
.imprint-builder-menu-item:hover, .imprint-builder-menu-item-colorpick:hover {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.imprint-builder-menu-item:nth-child(1):hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.imprint-builder-menu-item svg {
  width: 100%;
  height: 100%;
  padding: 15px;
}

.imprint-builder-menu-item-colorpick {
  width: 100%;
  height: 55px;
}

.imprint-builder-menu-item-colorpick.disabled {
  cursor: not-allowed;
  filter: invert(15%);
}

.imprint-builder-menu-item-colorpick svg {
  display: flex;
  width: 40%;
  height: 40%;
  margin: 8px auto 5px;
}
.imprint-builder-menu-item-colorpick .colorpick {
  width: 70%;
  height: 10px;
  margin: auto;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 3px;
}

.popup-button {
  border-radius: 3px;
  padding: 2px;
  cursor: pointer;
}

.popup-button path {
  filter: invert(100%);
}

.popup-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.popup-button-disabled {
  border-radius: 3px;
  padding: 2px;
  cursor: default;
}

.popup-button-disabled path {
  filter: invert(80%);
}

.popup-button-disabled:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

#imprint-builder .separator {
  width: 15px;
  height: 0.5px;
  background-color: rgb(109, 131, 141);
  transform: rotate(90deg);
}

.textColorPicker {
  width: 30px;
  height: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 3px;
}

.editingTextArea:focus {
  outline: none;
}

.fontSizeInput {
  outline: none;
  background-color: #d3dbe0;
  border-radius: 3px;
  border: 1px solid #6d838d !important;
  font-weight: bold;
  width: 28px;
  text-align: center;
}

.fontInput {
  outline: none;
  background-color: #d3dbe0;
  border: 1px solid #6d838d;
  border-radius: 3px;
  font-weight: bold;
  width: 100px;
  margin-right: 5px;
}