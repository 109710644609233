#model-card:hover {
  border: 1px solid #000000;
  cursor: pointer;
  transition: 0.3s;
}

#model-card svg {
  width: 100%;
  height: 100%;
  padding: 10px;
}