.mode-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 1200px;

  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 120px 1fr;
    grid-template-rows: 1fr 400px;
  }
}

.single-mode {
  max-width: 400px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 400px;
}

.mode-or {
  @media (min-width: 800px) {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
}

.mode-layout__card {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 400px;
  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
    grid-row: 1 / -1;
    align-items: start;
  }
}

.mode-visual {
  cursor: pointer;

  svg {
    max-width: 100%;
  }

  @media (min-width: 800px) {
    grid-row: 2 / 3;
    grid-column: 3 / 4;
    width: 100%;
    height: 100%;
  }
}
